import { VisitTypeTemplates } from '@/@types';
import { visitTypeTemplatesService } from '@/services';
import { useCallback, useMemo, useState } from 'react';

export function useVisitTypeTemplates() {
  const [visitTypeTemplates, setVisitTypeTemplates] = useState<VisitTypeTemplates[]>([]);

  const visitTypeTemplatesByLocation = useMemo(() => {
    const typesByLocation: Map<number, VisitTypeTemplates[]> = new Map();
    visitTypeTemplates.forEach((type) => {
      type.locations.forEach((loc) => {
        if (!typesByLocation.has(loc.id)) {
          typesByLocation.set(loc.id, []);
        }
        typesByLocation.get(loc.id)!.push(type);
      });
    });

    return typesByLocation;
  }, [visitTypeTemplates]);

  const findAllVisitTypeTemplates = useCallback(async () => {
    const { data } = await visitTypeTemplatesService.findAll();
    setVisitTypeTemplates(data);
  }, []);

  return {
    visitTypeTemplates,
    visitTypeTemplatesByLocation,
    findAllVisitTypeTemplates,
  };
}
