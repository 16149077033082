import { AnimatePresence, motion } from 'framer-motion';

import { AccordionChevron } from './AccordionChevron';
import { useAccordionContext } from './Accordion';
import { useMemo } from 'react';
import { AccordionChevronForm } from './AccordionChevronForm';

type Props = {
  id: string;
  title: string;
  children: React.ReactNode;
  formMode?: boolean;
};

export function AccordionItem({ id, title, children, formMode = false }: Props) {
  const { selectedItem, onToggle } = useAccordionContext();

  const isVisible = useMemo(() => selectedItem === id, [id, selectedItem]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.4 }}
      className='w-full'
    >
      <div
        className='w-full flex items-center justify-between gap-2 py-1 cursor-pointer'
        onClick={() => onToggle(id)}
      >
        {!formMode ? (
          <>
            <p className='font-semibold font-body text-xl text-gray-500'>{title}</p>
            <AccordionChevron isOpen={isVisible} />
          </>
        ) : (
          <>
            <AccordionChevronForm isOpen={isVisible} />
            <p className='text-xs flex-1 font-bold text-kelp'>{title}</p>
          </>
        )}
      </div>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className='py-2'
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}
