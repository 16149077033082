import { Atria, ConfirmedTypes, Patient, Room, VisitTypeTemplates } from '@/@types';
import { SchedulerHelper } from '@/helpers';
import { DateTime } from 'luxon';

export function getAppointmentsFromVisitTypeTemplates(
  visitTypeTemplates: VisitTypeTemplates[],
  rooms: Room[],
  roomsOptionsList: Room[],
  startTime: Date,
  patient?: Patient,
  confirmed?: ConfirmedTypes
) {
  const events: Atria.CreateAppointment.Body[] = [];
  let currentStartTime = DateTime.fromJSDate(startTime);

  for (const event of visitTypeTemplates) {
    let eventRooms = [...rooms];

    if (event.defaultRooms) {
      eventRooms = [
        ...eventRooms,
        ...roomsOptionsList.filter((r) =>
          event.defaultRooms.map((defaultRoom) => defaultRoom.id).includes(r.id)
        ),
      ];
    }

    const relatedRooms = SchedulerHelper.getRelatedNewRooms(
      eventRooms.map((er) => ({ id: er.id, label: er.name })),
      roomsOptionsList.map((er) => ({ id: er.id, label: er.name }))
    );

    events.push({
      date: currentStartTime.toUTC().toISO()!,
      duration: event.duration,
      rooms: relatedRooms.map((r) => ({ id: r.id })),
      title: event.appointmentTitle || event.name,
      ...(patient && {}),
      providers: event.defaultEquipment.map((e) => ({
        id: e.id,
        name: e.name,
        type: 'EQUIPMENT',
      })),
      ...(event.defaultType && {
        type: event.defaultType.name,
        typeId: event.defaultType.id,
      }),
      confirmed: confirmed || ConfirmedTypes.HOLD_CONFIRMED,
    });

    currentStartTime = currentStartTime.plus({ minutes: event.duration });
  }

  return events;
}
