import { useCallback, useMemo, useState } from 'react';
import { loader } from 'react-global-loader';
import { DateTime } from 'luxon';
import { VisitTypeTemplates, Atria, Room, ConfirmedTypes } from '@/@types';
import { useToastContext } from '@/contexts';
import { appointmentsService } from '@/services';
import { SelectActionStep } from './SelectActionStep';
import { SelectTemplateTypeStep } from './SelectTemplateTypeStep';
import { SelectStartAndRoom } from './SelectStartAndRoom';
import { getAppointmentsFromVisitTypeTemplates } from '@/utils';

type CreateAppointmentFromTemplateProps = {
  roomsOptionsList: Room[];
  suiteRooms: number[];
  currentDate: string;
  visitTypeTemplates: VisitTypeTemplates[];
  handleCreateNew: () => void;
  onSave: (newAppointments: Atria.Appointment[]) => void;
};

export const CreateAppointmentFromTemplate = ({
  roomsOptionsList,
  suiteRooms,
  currentDate,
  visitTypeTemplates,
  handleCreateNew,
  onSave,
}: CreateAppointmentFromTemplateProps) => {
  const { toast } = useToastContext();
  const [formStep, setFormStep] = useState(1);
  const [selectedTypes, setSelectedTypes] = useState<VisitTypeTemplates[]>([]);

  const suiteRoomsOptionsList = useMemo(() => {
    return roomsOptionsList.filter((room) => suiteRooms.includes(room.id));
  }, [roomsOptionsList, suiteRooms]);

  const selectType = useCallback(() => {
    setFormStep(2);
  }, []);

  const selectStartAndRoom = useCallback((types: VisitTypeTemplates[]) => {
    setSelectedTypes(types);
    setFormStep(3);
  }, []);

  const handleSubmit = useCallback(
    async (start: string, room: Room) => {
      loader.show();
      const rooms = [room!];
      const newEvents = getAppointmentsFromVisitTypeTemplates(
        selectedTypes,
        rooms,
        roomsOptionsList,
        DateTime.fromFormat(`${currentDate} ${start}`, 'M/d/yy T').toJSDate(),
        undefined,
        ConfirmedTypes.HOLD_CONFIRMED
      );

      try {
        const createMultipleAppointments: Atria.CreateMultipleAppointments.Body = {
          appointments: newEvents,
        };
        const { data: results } = await appointmentsService.createMultipleAppointments(
          createMultipleAppointments
        );
        if (results) {
          onSave(results);
        }
      } catch (error: any) {
        toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: error.response?.data?.message || 'Failed to create appointment',
          life: 3000,
        });
      } finally {
        loader.hide();
      }
    },
    [currentDate, onSave, roomsOptionsList, selectedTypes, toast]
  );

  return (
    <>
      {formStep === 1 && (
        <SelectActionStep handleCreateNew={handleCreateNew} handleCreateFromTemplate={selectType} />
      )}
      {formStep === 2 && (
        <SelectTemplateTypeStep
          visitTypeTemplates={visitTypeTemplates}
          handleSelectTemplateType={selectStartAndRoom}
        />
      )}
      {formStep === 3 && (
        <SelectStartAndRoom roomsOptionsList={suiteRoomsOptionsList} handleSubmit={handleSubmit} />
      )}
    </>
  );
};
