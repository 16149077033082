import { createContext, useCallback, useContext, useState } from 'react';

import { AthenaDepartment } from '@/@types/atriaService';
import { Region } from '@/@types/region';
import { locationService } from '@/services';
import { useLocalStorage } from '@/hooks';

type Type = {
  locations: Region[];
  selectedRegion: Region | null;
  findAllLocations: () => Promise<void>;
  getAthenaDepartmentsBySelectedRegion: () => AthenaDepartment[];
  getAthenaDepartments: () => AthenaDepartment[];
  getAthenaDepartmentDefault: () => AthenaDepartment | undefined;
  getAthenaDepartmentNameById: (athenaDepartmentId: number) => string | undefined;
  getAthenaDepartmentByName: (athenaDepartmentName: string) => AthenaDepartment | undefined;
  setSelectedRegion: (location: Region | null) => void;
  changeLocation: (regionId: number) => void;
};

const Context = createContext<Type>({
  locations: [],
  selectedRegion: null,
  findAllLocations: async () => {},
  getAthenaDepartmentsBySelectedRegion: () => [],
  getAthenaDepartments: () => [],
  getAthenaDepartmentDefault: () => undefined,
  getAthenaDepartmentNameById: () => undefined,
  getAthenaDepartmentByName: () => undefined,
  setSelectedRegion: async () => {},
  changeLocation: () => {},
});

type Props = {
  children?: React.ReactNode;
};

export function LocationContext({ children }: Props) {
  const [locations, setLocations] = useState<Region[]>([]);
  const [selectedRegion, setSelectedRegion] = useLocalStorage<Region | null>('location', null);

  const findAllLocations = useCallback(async () => {
    const { data } = await locationService.findAll();
    setLocations(data);
  }, []);

  const getAthenaDepartmentsBySelectedRegion = () => {
    return selectedRegion?.athenaDepartments || [];
  };

  const getAthenaDepartments = () => {
    return locations.map((location) => location.athenaDepartments).flat(1);
  };

  const getAthenaDepartmentDefault = (): AthenaDepartment | undefined => {
    return selectedRegion?.athenaDepartments?.[0] || undefined;
  };

  const getAthenaDepartmentNameById = useCallback(
    (athenaDepartmentId: number): string | undefined => {
      return selectedRegion?.athenaDepartments?.find(
        (department) => department.departmentId === athenaDepartmentId
      )?.name;
    },
    [selectedRegion]
  );

  const getAthenaDepartmentByName = useCallback(
    (athenaDepartmentName: string): AthenaDepartment | undefined => {
      return locations
        .map((location) => location.athenaDepartments)
        .flat(1)
        .find((department) => department.name === athenaDepartmentName);
    },
    [locations]
  );

  const changeLocation = useCallback(
    (regionId: number) => {
      setSelectedRegion(locations.find((region) => region.id == regionId) || null);
    },
    [locations, setSelectedRegion]
  );

  return (
    <Context.Provider
      value={{
        locations,
        selectedRegion,
        setSelectedRegion,
        findAllLocations,
        getAthenaDepartmentsBySelectedRegion,
        getAthenaDepartments,
        getAthenaDepartmentDefault,
        getAthenaDepartmentNameById,
        getAthenaDepartmentByName,
        changeLocation,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export const useLocation = () => useContext(Context);
