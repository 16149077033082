import classNames from 'classnames';
import { motion } from 'framer-motion';

type Props = {
  isOpen?: boolean;
};

export function AccordionChevron({ isOpen = false }: Props) {
  return (
    <motion.i
      initial={isOpen ? 'open' : 'closed'}
      variants={{
        open: { rotate: '0deg', opacity: 1 },
        closed: { rotate: '180deg', opacity: 1 },
      }}
      transition={{ damping: 40 }}
      animate={isOpen ? 'open' : 'closed'}
      className={classNames('pi pi-chevron-up ', {
        'text-gray-700': isOpen,
        'text-gray-500': !isOpen,
      })}
    ></motion.i>
  );
}
