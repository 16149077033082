import classNames from 'classnames';
import { DateTime } from 'luxon';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  AppointmentType,
  AppointmentWithDate,
  Atria,
  Provider,
  SelectOption,
  ConfirmedTypes,
} from '@/@types';
import { useSchedulerDuplicateAppointment } from '@/hooks';

import {
  DuplicateMultipleAppointmentsFormSchemaResolver,
  DuplicateMultipleAppointmentsFormType,
} from './DuplicateMultipleAppointmentFormSchema';
import { DateTimeHelper } from '@/helpers';
import { Permission, useAuthContext } from '@/contexts';
import { FormField } from './FormField';
import { filterRoomsByPermission } from '../../utils';

type Props = {
  appointments: AppointmentWithDate[];
  rooms: SelectOption[];
  providers: Provider[];
  appointmentTypes: AppointmentType[];
  onCancel: VoidFunction;
  onSave: (newAppointment: Atria.Appointment[]) => void;
};

export const DuplicateMultipleAppointmentForm = ({
  appointments,
  rooms,
  appointmentTypes,
  onCancel,
  onSave,
}: Props) => {
  const { control, formState, handleSubmit, reset } =
    useForm<DuplicateMultipleAppointmentsFormType>({
      resolver: DuplicateMultipleAppointmentsFormSchemaResolver,
    });
  const { getDuplicateMultipleAppointments, createMultipleAppointments } =
    useSchedulerDuplicateAppointment();
  const hasErrors = useMemo(() => Object.values(formState.errors).length > 0, [formState]);

  const handleCancelFormButton = useCallback(() => {
    reset();
    onCancel();
  }, [reset, onCancel]);

  const { hasPermission } = useAuthContext();

  const roomsOptions = useMemo(() => {
    return filterRoomsByPermission(rooms, hasPermission(Permission['VIEW_ROOMS:TELE']));
  }, [rooms, hasPermission]);

  const onSubmit = useCallback(
    async (values: DuplicateMultipleAppointmentsFormType) => {
      const date = DateTime.fromFormat(values.date!, 'M/d/yy').toUTC().toJSDate();
      const holdedAppointments = appointments.map((a) => ({
        ...a,
        confirmed: ConfirmedTypes.HOLD_CONFIRMED,
      }));
      const list = getDuplicateMultipleAppointments(
        holdedAppointments,
        date,
        appointmentTypes,
        rooms,
        values.rooms!
      );

      const results = await createMultipleAppointments(list);
      if (results) {
        onSave(results);
      }
    },
    [
      appointmentTypes,
      appointments,
      createMultipleAppointments,
      getDuplicateMultipleAppointments,
      onSave,
      rooms,
    ]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col'>
      <div className='flex mt-4'>
        <Controller
          control={control}
          name='date'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={true}
              label='Date'
              labelRef='date'
            >
              <Calendar
                {...field}
                inputId='date'
                placeholder='Date'
                dateFormat='mm/dd/yy'
                locale='en'
                minDate={DateTime.fromFormat(
                  DateTimeHelper.returnOnlyDateYYYYmmDD(new Date()),
                  'yyyy-MM-dd'
                ).toJSDate()}
                className={classNames('h-[37px]', {
                  'border-rust': !!fieldState.error,
                })}
                inputClassName={classNames({
                  'border-rust': !!fieldState.error,
                })}
              />
            </FormField>
          )}
        />
      </div>

      <div className='flex mt-4'>
        <Controller
          control={control}
          name='rooms'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={true}
              label='Rooms'
              labelRef='roomsSelected'
            >
              <MultiSelect
                id='rooms'
                placeholder='Select rooms'
                filter
                optionLabel='label'
                options={roomsOptions}
                onChange={(e) => field.onChange(e.value)}
                onBlur={field.onBlur}
                value={field.value}
                className={classNames('h-[37px]', {
                  'border-rust': !!fieldState.error,
                })}
              />
            </FormField>
          )}
        />
      </div>
      <div className='w-full flex flex-col gap-2 mt-4'>
        <Button label='Save' size='small' className='w-full' type='submit' disabled={hasErrors} />
        <Button
          label='Cancel'
          size='small'
          className='w-full'
          type='reset'
          onClick={handleCancelFormButton}
          outlined
        />
      </div>
    </form>
  );
};
