/**
 * Converts a value into an array.
 *
 * @examples
 * toArray(5); // Output: [5]
 * toArray([1, 2, 3]); // Output: [1, 2, 3]
 * toArray(null); // Output: []
 * toArray(undefined); // Output: []
 *
 **/

export const toArray = <T>(value: T | T[] | null | undefined): T[] => {
  if (value == null) return [];
  return Array.isArray(value) ? value : [value];
};
