import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import GCalIcon from '@/assets/gcal-icon.png';
import AthenaIcon from '@/assets/athena-icon.png';

import { AppointmentWithDate, Origin } from '@/@types';

type CalendarEventProps = {
  appointment: AppointmentWithDate;
  isWeeklyViewEvent?: boolean;
};

export const CalendarEvent = ({ appointment, isWeeklyViewEvent = false }: CalendarEventProps) => {
  const formattedStartAndEndTime = useMemo(() => {
    const start = DateTime.fromJSDate(appointment.date);
    const end = DateTime.fromJSDate(appointment.end);
    const formattedStart =
      start.toFormat('mm') === '00' ? start.toFormat('h') : start.toFormat('h:mm');
    const formattedEnd = (
      end.toFormat('mm') === '00' ? end.toFormat('ha') : end.toFormat('h:mma')
    ).toLowerCase();

    return `${formattedStart} - ${formattedEnd}`;
  }, [appointment]);

  const eventClasses = useMemo(() => {
    const classes = classNames('flex', {
      'flex-row pr-[6px]': appointment.duration <= 15 && !isWeeklyViewEvent,
      'flex-col': appointment.duration > 15,
    });
    return classes;
  }, [appointment.duration, isWeeklyViewEvent]);

  const patientName = useMemo(() => {
    const names = appointment.patientName?.split(' ');

    if (!names) return '';

    if (names.length > 1) {
      return `${names[0][0]}${names[names.length - 1][0]} ${appointment.patientId}`;
    }

    return names[0];
  }, [appointment]);

  return (
    <div className='relative'>
      <div
        className={classNames([
          eventClasses,
          'relative',
          {
            'mr-4 text-nowrap text-ellipsis overflow-hidden':
              appointment.athenaAppointmentId || appointment.googleCalendarEventId,
            'mr-8':
              appointment.athenaAppointmentId &&
              appointment.googleCalendarEventId &&
              appointment.duration <= 15,
          },
        ])}
      >
        <p
          className={classNames([
            'text-sm font-bold leading-4 whitespace-nowrap overflow-ellipsis  mr-1 overflow-hidden shrink-0',
            appointment.duration > 15 &&
              !isWeeklyViewEvent &&
              'whitespace-nowrap overflow-ellipsis mb-[2px]',
          ])}
        >
          {appointment.title}
        </p>
        <p className='text-sm leading-4 mt-0 text-nowrap'>{formattedStartAndEndTime}</p>
        {appointment.origin != Origin.Google && (
          <div
            className={classNames([
              'font-normal text-[length:var(--font-sm)]',
              appointment.duration <= 15 && 'flex flex-row pr-[6px] text-nowrap ml-[2px]',
            ])}
          >
            {appointment.patientName && (
              <p className='whitespace-nowrap overflow-hidden overflow-ellipsis'>{patientName}</p>
            )}
            {appointment.provider && appointment.provider.label && (
              <p>{appointment.provider.label}</p>
            )}
            {appointment.rooms?.map((room) => <p key={room.id}>{room.label}</p>)}
          </div>
        )}
        <div
          className={classNames([
            'font-normal text-[length:var(--font-sm)]',
            appointment.duration <= 15 && 'flex flex-row pr-[6px] text-nowrap ml-[2px]',
          ])}
        ></div>
      </div>

      {(appointment.athenaAppointmentId || appointment.googleCalendarEventId) && (
        <div className='absolute top-0 right-0'>
          {appointment.athenaAppointmentId && appointment.googleCalendarEventId ? (
            <div className={classNames({ flex: appointment.duration <= 15 })}>
              <img src={AthenaIcon} alt='Synced to Athena' width={13} height={13} />
              <img
                src={GCalIcon}
                alt='Synced to Google Calendar'
                aria-label='Synced to Google Calendar'
                width={13}
                height={13}
              />
            </div>
          ) : appointment.athenaAppointmentId ? (
            <img src={AthenaIcon} alt='Synced to Athena' width={13} height={13} />
          ) : (
            <img src={GCalIcon} alt='Synced to Google Calendar' width={13} height={13} />
          )}
        </div>
      )}
    </div>
  );
};
