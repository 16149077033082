import { useCallback, useEffect, useMemo, useState } from 'react';
import { loader } from 'react-global-loader';

import { useAppointmentTypeContext, useToastContext, useLocation } from '@/contexts';
import { useSchedulerSavedViewContext } from '@/features/Calendar/contexts/schedulerSavedViewContext';
import { useFilters, useProviders, useRooms } from '@/hooks';
import { Room } from '@/@types';
import { Scheduler } from '@/components';
import { SchedulerLayout } from '@/components/Layouts';
import { AthenaStatus } from '@/components/AthenaStatus';
import { useVisitTypeTemplates } from '@/features/AppointmentActions';

export function SchedulerDailyPage() {
  const { selectedRegion } = useLocation();
  const { findAllViewsForUser, selectCurrentSchedulerView } = useSchedulerSavedViewContext();
  const { visitTypeTemplates, visitTypeTemplatesByLocation, findAllVisitTypeTemplates } =
    useVisitTypeTemplates();
  const { findAllRooms, rooms: roomsOptionsList } = useRooms();
  const { findAllProviders } = useProviders();
  const { getAppointmentTypes } = useAppointmentTypeContext();
  const { toast } = useToastContext();
  const [showFilters, setShowFilters] = useState(false);
  const {
    providers: providersOptionsList,
    equipmentList,
    providersList,
    cmosFilterList,
  } = useProviders();
  const { appointmentTypesOptionsList } = useAppointmentTypeContext();
  const { filters, setFilterItem, isViewingDefaultView, setFilters } = useFilters();

  const roomsFilteredOptions: Room[] = useMemo(() => {
    return roomsOptionsList.filter((room) => room.location?.id === selectedRegion?.id) || [];
  }, [roomsOptionsList, selectedRegion]);

  const initialLoad = useCallback(async () => {
    loader.show();
    try {
      await Promise.all([
        findAllRooms(),
        findAllProviders(),
        findAllViewsForUser(),
        getAppointmentTypes(),
        findAllVisitTypeTemplates(),
      ]);
      loader.hide();
    } catch (error) {
      toast?.current?.show({
        className: 'text-xl opacity-100',
        severity: 'error',
        summary: 'Failed to load important data',
        detail: `Reload the page. If error persists, message Slack channel #scheduler-buildout or contact Teresa Rufin`,
        life: 60000,
      });
    }
  }, [
    findAllProviders,
    findAllRooms,
    findAllViewsForUser,
    findAllVisitTypeTemplates,
    getAppointmentTypes,
    toast,
  ]);

  const handleOnLocationChange = useCallback(
    (regionId: number) => {
      setFilters((prev) => ({
        ...prev,
        location: regionId,
        rooms: [],
        equipment: [],
        personnel: [],
        cmos: [],
        patients: [],
      }));
      selectCurrentSchedulerView(null);
    },
    [selectCurrentSchedulerView, setFilters]
  );

  useEffect(() => {
    initialLoad();
  }, [initialLoad]);

  return (
    <SchedulerLayout
      isLocationRequired={true}
      onLocationChangeCallback={handleOnLocationChange}
      currentDate={filters.date}
    >
      <div className='h-full p-4'>
        <AthenaStatus />
        <Scheduler
          showFilters={showFilters}
          filters={filters}
          setFilterItem={setFilterItem}
          roomsOptionsList={roomsFilteredOptions}
          equipmentFilterList={equipmentList}
          providersFilterList={providersList}
          providersOptionsList={providersOptionsList}
          appointmentTypesList={appointmentTypesOptionsList}
          cmosFilterList={cmosFilterList}
          setShowFilters={setShowFilters}
          isViewingDefaultView={isViewingDefaultView}
          visitTypeTemplates={
            selectedRegion
              ? visitTypeTemplatesByLocation.get(selectedRegion.id) || []
              : visitTypeTemplates
          }
        />
      </div>
    </SchedulerLayout>
  );
}
