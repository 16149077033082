import { useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { VisitTypeTemplates } from '@/@types';

type SelectTemplateTypeStepProps = {
  visitTypeTemplates: VisitTypeTemplates[];
  handleSelectTemplateType: (types: VisitTypeTemplates[]) => void;
};

export const SelectTemplateTypeStep = ({
  visitTypeTemplates,
  handleSelectTemplateType,
}: SelectTemplateTypeStepProps) => {
  const [appointmentTemplatesTypesSelected, setAppointmentTemplatesTypesSelected] = useState<
    VisitTypeTemplates[]
  >([]);

  return (
    <div className='p-[var(--space-sm)]'>
      <ul>
        <li className='p-[var(--space-sm)] hover:bg-[var(--gray-1)] cursor-pointer'>
          <Checkbox
            name='templateType'
            inputId='selectAll'
            value='selectAll'
            checked={appointmentTemplatesTypesSelected.length === visitTypeTemplates.length}
            onChange={() => {
              if (appointmentTemplatesTypesSelected.length === visitTypeTemplates.length) {
                setAppointmentTemplatesTypesSelected([]);
              } else {
                setAppointmentTemplatesTypesSelected(visitTypeTemplates);
              }
            }}
          />
          <label htmlFor='selectAll' className='ml-[var(--space-xs)]'>
            Select all
          </label>
        </li>
        {visitTypeTemplates.map((type) => {
          const selectedTemplateIndex = appointmentTemplatesTypesSelected.findIndex(
            (templateType) => templateType.id === type.id
          );

          return (
            <li
              key={type.name}
              className='p-[var(--space-sm)] hover:bg-[var(--gray-1)] cursor-pointer'
            >
              <Checkbox
                name='templateType'
                inputId={type.name}
                value={type.name}
                checked={appointmentTemplatesTypesSelected.includes(type)}
                onChange={() => {
                  if (appointmentTemplatesTypesSelected.includes(type)) {
                    setAppointmentTemplatesTypesSelected((prev) =>
                      prev.filter((t) => t.name !== type.name)
                    );
                  } else {
                    setAppointmentTemplatesTypesSelected((prev) => [...prev, type]);
                  }
                }}
              />
              <label htmlFor={type.name} className='ml-[var(--space-xs)]'>
                {selectedTemplateIndex !== -1 ? `${selectedTemplateIndex + 1}. ` : ''}
                {type.name}
              </label>
            </li>
          );
        })}
      </ul>
      <Button
        className='flex ml-[auto]'
        icon='pi pi-angle-double-right'
        size='small'
        rounded
        outlined
        onClick={() => handleSelectTemplateType(appointmentTemplatesTypesSelected)}
        disabled={appointmentTemplatesTypesSelected.length === 0}
      />
    </div>
  );
};
